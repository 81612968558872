import { Link, Route, Routes } from "react-router-dom";
import ESLTU from "./unions/TheDouglas";
import TheDouglas from "./unions/TheDouglas";
import TheDouglasLogo from "./images/tenant-unions/Douglas/logo.png";

function Unions() {

    
  return (
    <div>
      <h1>Our Tenant Unions</h1>
      <p>Here's some of the Tenant Unions we're currently working with. Click to see how you can help with our efforts!</p>
        <div className='unions-container'>
          <nav>
            <Link to="esltu">
              <div>

              </div>
            </Link>
            <Link to="the-douglas">
              <div>
                <h3>The Douglas Tenants Union</h3>
                <img src={TheDouglasLogo} alt="The Douglas"></img>
              </div>
            </Link>
          </nav>
        </div> 
    </div>
  );
}

export default Unions;