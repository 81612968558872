import { useRef, useState } from 'react';
import Popup from 'reactjs-popup';

const images = [
    "logoooo.png",
    "logo-square.png",
    "red-logo-text.png",
    "RAN.png",
    "icon.png",
    "logo.png",
    "fist-poster.png",
    "sticker.PNG",
    "e.png",
    "image.jpg",
    "crabs.png",
    "monster.png",
    "Crowski.png",
    "building-sky-poster.png",
]

function Art() {
    const [current, setCurrent] = useState(null as string | null)

    const popup = useRef(null)

    return <div className="prop">
        <Popup
            ref={popup}
            position="right center">
                <div className='modal'>
                    <img
                    className='popup-img'
                    alt={current!} 
                    src={`images/${current}`}></img>
                </div>

        </Popup>
        {
            images.map(src => 
                <img
                    alt={src}
                    src={`images/${src}`}
                    onClick={() => {
                        setCurrent(src);
                        (popup.current as any).open()
                    }}>

                </img>
            )
        }

    </div>
}

export default Art;