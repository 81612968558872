import { Outlet } from "react-router-dom";
import slide1 from "../images/tenant-unions/Douglas/one.jpg"; 
import slide2 from "../images/tenant-unions/Douglas/two.jpg"; 
import TheDouglasLogo from "../images/tenant-unions/Douglas/logo.png";

function TheDouglas() {
    return (
      <div className="tenant-union-page">
        <img className="tu-logo" src={TheDouglasLogo} alt="The Douglas"></img>
        <h1>The Douglas Tenants Union</h1>
        <div className="slides">
          <img src={slide2} alt="slide2"></img>
          <img src={slide1} alt="slide1"></img>
        </div>
        <div className="review-bomb">
          <h3>Leave a Review at the Douglas Apartments!</h3>
          <div>
            <div className="review-links">
              <a href="https://www.google.com/maps/place/The+Douglas/@45.5090455,-122.6764751,1405m/data=!3m2!1e3!4b1!4m6!3m5!1s0x54950a12e73d17c7:0xfcf28095e3cf24c5!8m2!3d45.5090455!4d-122.6739002!16s%2Fg%2F1v27v68v?authuser=0&entry=ttu&g_ep=EgoyMDI1MDEyMi4wIKXMDSoASAFQAw%3D%3D">
                Google Reviews
              </a>
              <a href="https://www.apartments.com/the-douglas-portland-or/s34z0h6/">
                Apartments.com
              </a>
              <a href="https://rateyourlandlordpdx.com/address/1811%20S%20RIVER%20DR">
                RateYourLandlordPDX.com
              </a>
              <a href="https://m.yelp.com/biz/the-douglas-portland-2">
                Yelp.com
              </a>
            </div>
            <h4>Example scripts:</h4>
            <p>Make sure to make some edits to these before posting, or make your own!</p>
            <ol className="review-scripts">
              <li>The Douglas maintenance efforts are horrendous. Broken elevators, endless pipe leaks, and zero accountability—tenants are left footing the bill for water leaks while management turns a blind eye. It's disgraceful! 
              </li>
              <li>Living in Douglas is a nightmare with constantly downed elevators and unreliable access. In 2024 it was down for 5 months. The landlord’s complete inaction is infuriating and making daily life unbearable for everyone. This is unacceptable! Urgent repairs are needed NOW! 
              </li>
              <li>The irrigation and draining system are an absolute disaster, causing floods and wrecking our property foliage. We're fed up with paying for ongoing damages while the landlord does nothing. Now there are constant water bursts in units and the parking garage 
              </li>
              <li>The parking garage that we pay $200 is flooded 9 months out of the year, causing unsafe conditions and personal accidents. The garage door to the tenant car park is frequently broken and left open to be repaired for weeks which caused my car to be vandalized. Enough is enough! 
              </li>
              <li>Don’t rent here at The Douglas landlords are using old, flimsy parts to fix secure doors is a blatant safety risk for all tenants. Our landlord's refusal to invest in proper repairs is disgraceful and dangerous. The property management staff turnover is constant, we deserve far better than this negligent behavior! The property filth and not regularly cleaned attracted rodent and pest infestation. It’s a dump!
              </li>
              <li>Douglas lacks timely lease renewals which are crucial, yet our landlord's chaotic management is causing endless stress for tenants. This lack of professionalism is completely unacceptable and downright disrespectful. 
              </li>
              <li>Constant water bursts into units and garage flooding adds to the chaos causing tenants to relocate to other units and tenants footing the bill for damaged personal belongs and other units are affected by power outages. We deserve far better than this negligent behavior!
              </li>
              <li>The Douglas management bullies and intimidates tenants “if you’re not happy, leave.” Rude and abusive behavior by management is unsettling. They are not there to help. The constant stolen packages in the leasing office for years and they haven’t addressed the issue. Tenants lost thousands of dollars of merchandise. They don’t listen and try to help. We are being ignored.
              </li>
            </ol>

          </div>
        </div>
      </div>
      
    );
  }
  
  export default TheDouglas;